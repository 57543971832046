  import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import HeroWithoutImage from "../components/Hero/HeroWithoutImage"
import SEO, { seoPropsMapping } from "../components/seo"
import moment from "moment"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../components/VindiciaComponents"

const Blog = ({ data }) => {
  const {
    author,
    publish_date,
    article_content,
    headline,
    linked_whitepaper,
    tile_image_for_blog_list__required_,
  } = data.prismicBlogEntry.data

  const categoryNodes = data.allPrismicBlogCategories
    ? data.allPrismicBlogCategories.edges.sort((a, b) => (a.node.uid > b.node.uid ? 1 : -1))
    : null

  const seoProps = seoPropsMapping(data.prismicBlogEntry.data, {
    seo_title: headline.text,
    seo_description: `Blog post by ${author.document.data.author_name} at Vindicia | Failed Payment Recovery`,
    image: tile_image_for_blog_list__required_ && tile_image_for_blog_list__required_.fixed.src,
  })

  return (
    <Layout>
      <SEO {...seoProps} />
      <HeroWithoutImage title={"Blog"} />
      <section className="uk-section">
        <div className="uk-container">
          <div className="" data-uk-grid>
            <div className="uk-width-expand">
              <p className="uk-text-muted">
                {moment(publish_date).format("MMMM D, YYYY")} | Authored by:{" "}
                <Link to={`/author/${author.uid}`}>{author.document.data.author_name}</Link>
              </p>
              <h2 className="uk-margin-remove-top">{headline.text}</h2>
              <div
                className="prismic-content uk-margin-small-top"
                dangerouslySetInnerHTML={{ __html: article_content.html }}
              />
              <div className="uk-section uk-section-small">
                <div id="author-wrap" className="uk-container uk-container-small">
                  <h3 className="uk-heading-line">
                    <span>About Author</span>
                  </h3>
                  <div className="uk-grid uk-grid-medium uk-flex uk-flex-middle" data-uk-grid>
                    {author.document.data && author.document.data.profile_photo ? (
                      <div className="uk-width-auto">
                        <Image
                          src={author.document.data.profile_photo.fixed.src}
                          srcSet={author.document.data.profile_photo.fixed.srcSet}
                          alt={author.document.data.author_name}
                          style={{
                            width: "150px",
                          }}
                        />
                      </div>
                    ) : null}
                    <div className="uk-width-expand">
                      <h4 className="uk-margin-remove">
                        <Link to={`/author/${author.uid}`}>{author.document.data.author_name}</Link>
                      </h4>
                      <span
                        className="uk-text-small uk-text-muted"
                        dangerouslySetInnerHTML={{
                          __html: author.document.data.author_bio.html,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-4@m">
              {linked_whitepaper.document && (
                <div className="uk-panel uk-padding-small uk-tile-muted uk-border-rounded">
                  <h3>
                    <span>Whitepaper</span>
                  </h3>
                  <p>{linked_whitepaper.document.data.title.text}</p>
                  <Link to={`/resources/white-papers/${linked_whitepaper.uid}`}>Download</Link>
                </div>
              )}
              {categoryNodes && (
                <>
                  <h3>
                    <span>Categories</span>
                  </h3>
                  <ul className="uk-list uk-margin-left">
                    {categoryNodes.map(c => (
                      <li key={c.node.uid}>
                        <Link to={`/blog/category/${c.node.uid}`}>{c.node.data.label}</Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Blog)
export const blogDetail = graphql`
  query blogDetail($uid: String) {
    prismicBlogEntry(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type
        canonical_url {
          url
        }
        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        headline {
          text
        }
        article_content {
          html
        }
        publish_date
        tile_image_for_blog_list__required_ {
          fixed(width: 500) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        author {
          uid
          document {
            ... on PrismicAuthors {
              data {
                author_bio {
                  html
                }
                author_name
                role
                profile_photo {
                  fixed(width: 150) {
                    ...GatsbyPrismicImageFixed_noBase64
                  }
                }
              }
            }
          }
        }
        categories {
          blog_tags {
            document {
              ... on PrismicBlogCategories {
                id
                data {
                  label
                }
              }
            }
          }
        }
        linked_whitepaper {
          uid
          slug
          document {
            ... on PrismicWhitepapers {
              id
              data {
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
    allPrismicBlogCategories {
      edges {
        node {
          data {
            label
          }
          uid
        }
      }
    }
  }
`

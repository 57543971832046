import React from "react"
import classnames from "classnames"

const HeroWithoutImage = ({ title, subtitle, text_alignment = "left" }) => {
  const heroClass = classnames({
    "uk-flex-between": text_alignment === "right" || text_alignment === "left",
    "uk-margin-large-left": text_alignment === "right",
    "uk-flex uk-width-2-5@m uk-container uk-flex-auto uk-position-relative uk-flex-wrap uk-flex-middle": true,
  })

  const heroTextClass = classnames({
    "margin-bottom": text_alignment === "left",
    "margin-top": text_alignment === "right",
  })

  return (
    <header className="hero-without-image">
      <div className="uk-container">
        <div className="uk-flex uk-flex-wrap uk-flex-middle">
          <div
            className={`uk-dark uk-container uk-flex-auto uk-margin-large-top uk-margin-large-bottom uk-position-relative`}
          >
            <div className="uk-container">
              <div className="uk-flex uk-flex-wrap uk-flex-middle" style={{ gap: "15px" }}>
                <div className={heroClass}>
                  <div
                    className={heroTextClass}
                    data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
                  >
                    <h1 className="uk-margin-large-top">{title}</h1>
                    {subtitle ? <h4 className="uk-margin-remove-top">{subtitle}</h4> : null}
                  </div>
                </div>
                <div className="uk-width-2-5@m"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeroWithoutImage
